<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-10">
      <div class="w-100 flex items-center justify-between">
        <div v-if="isLoading" class="ml-16">
          <em class="fa fa-spinner fa-spin text-white"></em>
        </div>
        <h1
          v-else-if="
            subscriptionPlatform !== 'Free Trial' &&
            !adminAndLocationRoles.includes(user.role)
          "
          class="text-white text-lg font-semibold"
        >
          Available seats: {{ availableSeats }}
        </h1>
        <h1 v-else class="text-white text-lg font-semibold">Players</h1>
        <div class="flex items-center justify-end my-3 mt-6">
          <button
            id="addPlayer"
            class="text-color submit-btn font-bold px-6 py-2 w-full"
            @click="
              subscriptionPlatform === 'Apple' ||
              subscriptionPlatform === 'Google'
                ? addInAppPurchasePlayers()
                : addStripePlayer()
            "
            v-if="
              subscriptionPlatform !== 'Free Trial' &&
              !adminAndLocationRoles.includes(user.role)
            "
            :disabled="isLoading"
          >
            Add new player
          </button>
        </div>
      </div>
      <div
        class="bg-white flex flex-wrap xl:grid xl:grid-cols-3 xl:grid-rows-1 gap-2 2xl:gap-6 p-6 mt-7"
      >
        <CardPlayer
          v-for="player in players"
          :key="player.id"
          :player="player"
          :isSubscriptionExpired="isSubscriptionExpired"
          :isSubscriptionSuspended="isSubscriptionSuspended"
          :isLoading="isLoading"
          :platform="subscriptionPlatform"
          :expiryDate="subscriptionExpiryDate"
          :availableSeats="availableSeats"
          :noSubscriptionFound="noSubscriptionFound"
          :noSubscriptionInfoNeeded="noSubscriptionInfoNeeded"
          @updateAvailableSeats="updateAvailableSeats"
          @getSubscriptionInfo="getSubscriptionInfo"
        ></CardPlayer>
      </div>
    </div>
  </section>
  <InformationModal
    :show="showNotSeatsModal"
    @update:show="showNotSeatsModal = $event"
    @confirm="showNotSeatsModal = false"
    :title="'Add new player'"
    :content="getNotAvailableSeatsContent()"
  />
  <InformationModal
    :show="showMaxSeatsModal"
    @update:show="showMaxSeatsModal = $event"
    @confirm="showMaxSeatsModal = false"
    :title="'Add new player'"
    :content="getMaxAvailableSeatsContent()"
  />
  <InformationModal
    :show="showSubscriptionSuspendedModal"
    @update:show="showSubscriptionSuspendedModal = $event"
    @confirm="showSubscriptionSuspendedModal = false"
    :title="'Add new player'"
    :content="getSubscriptionSuspendedContent()"
  />
  <AddPlayerSubscriptionModal
    :show="showAddPlayerSubscriptionModal"
    @update:show="showAddPlayerSubscriptionModal = $event"
    @confirm="handleAddPlayerConfirm"
    :isLoading="isAddingPlayerToStripeSubscription"
  />
  <PaymentInformationModal
    :show="showPaymentModal"
    @update:show="showPaymentModal = $event"
    @confirm="handlePaymentMethod"
    :isLoading="isLoadingPayment"
    confirmationText="Add player"
  />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

import CardPlayer from "@/components/Players/CardPlayer.vue";
import {
  getProfiles,
  getSubscriptionProvider,
  getNumberDependents,
} from "@/services/user/user";
import {
  inAppPurchasedependentsToAddOrReactivate,
  getAppStoreSubscriptionInfo,
  getGooglePlayStoreSubscriptionInfo,
} from "@/services/inAppPurchase/inAppPurchase.js";
import {
  getStripeSubscriptionInfo,
  addNewPlayerToStripeSubscription,
  attachPaymentMethod,
} from "@/services/stripe/stripe.js";
import InformationModal from "@/components/profile/modals/InformationModal.vue";
import AddPlayerSubscriptionModal from "@/components/profile/modals/AddPlayerSubscriptionModal.vue";
import PaymentInformationModal from "@/components/profile/modals/PaymentInformationModal.vue";
import stripe from "@/plugins/stripe";

const store = useStore();
const router = useRouter();
const toast = useToast();

const user = computed(() => store.getters["user/getUserData"]);

const players = ref([]);
const isSubscriptionExpired = ref(false);
const isSubscriptionSuspended = ref(false);
const isLoading = ref(true);
const availableSeats = ref(0);
const maxSeats = ref(4);
const subscriptionPlatform = ref("Stripe");
const showNotSeatsModal = ref(false);
const showMaxSeatsModal = ref(false);
const subscriptionExpiryDate = ref("");
const showSubscriptionSuspendedModal = ref(false);
const noSubscriptionFound = ref(false);
const showAddPlayerSubscriptionModal = ref(false);
const isAddingPlayerToStripeSubscription = ref(false);
const showPaymentModal = ref(false);
const isLoadingPayment = ref(false);
const adminAndLocationRoles = ref([
  "Mastermind_admin",
  "Location_admin",
  "Location_delegate",
  "Sponsor_account",
]);
const noSubscriptionInfoNeeded = ref(false);

onMounted(async () => {
  players.value = await getProfiles();
  if (adminAndLocationRoles.value.includes(user.value.role)) {
    noSubscriptionInfoNeeded.value = true;
    isLoading.value = false;
    return;
  }
  await getSubscriptionInfo();
});

async function getSubscriptionInfo() {
  const subscriptionProvider = await getSubscriptionProvider();
  subscriptionPlatform.value = subscriptionProvider;

  try {
    let subscription;
    if (subscriptionPlatform.value === "Apple") {
      subscription = await getAppStoreSubscriptionInfo();
    } else if (subscriptionPlatform.value === "Google") {
      subscription = await getGooglePlayStoreSubscriptionInfo();
    } else if (subscriptionPlatform.value === "Stripe") {
      subscription = await getStripeSubscriptionInfo();
    } else {
      noSubscriptionFound.value = true;
      isLoading.value = false;
      return;
    }

    isSubscriptionExpired.value = subscription.is_expired;
    subscriptionExpiryDate.value = subscription.subscription_end_date;
    isSubscriptionSuspended.value =
      subscription.subscription_status !== "active";
    await updateAvailableSeats();
  } catch (error) {
    isSubscriptionExpired.value = true;
    isSubscriptionSuspended.value = true;
    noSubscriptionFound.value = true;
  } finally {
    isLoading.value = false;
  }
}

async function updateAvailableSeats() {
  if (isSubscriptionSuspended.value) {
    availableSeats.value = 0;
  } else if (subscriptionPlatform.value === "Stripe") {
    const numberOfPlayers = await getNumberDependents(user.value.id);
    availableSeats.value = numberOfPlayers.data.dependents_to_register;
  } else if (
    subscriptionPlatform.value === "Apple" ||
    subscriptionPlatform.value === "Google"
  ) {
    const dependentsToProcess =
      await inAppPurchasedependentsToAddOrReactivate();
    availableSeats.value = dependentsToProcess.available_seats;
  }
}

function addInAppPurchasePlayers() {
  if (isSubscriptionSuspended.value) {
    showSubscriptionSuspendedModal.value = true;
  } else if (availableSeats.value === 0) {
    showNotSeatsModal.value = true;
  } else if (
    subscriptionPlatform.value === "Apple" &&
    availableSeats.value >= maxSeats.value
  ) {
    showMaxSeatsModal.value = true;
  } else {
    redirectToRegisterPlayers(availableSeats.value);
  }
}

function addStripePlayer() {
  if (isSubscriptionSuspended.value) {
    showSubscriptionSuspendedModal.value = true;
  } else if (availableSeats.value > 0) {
    redirectToRegisterPlayers(1);
  } else {
    showAddPlayerSubscriptionModal.value = true;
  }
}

async function handleAddPlayerConfirm() {
  isAddingPlayerToStripeSubscription.value = true;
  try {
    await addNewPlayerToStripeSubscription();
    redirectToRegisterPlayers(1);
  } catch (error) {
    if (error.code === 400) {
      showPaymentModal.value = true;
    }
    handleMessage(error.message, true);
  } finally {
    isAddingPlayerToStripeSubscription.value = false;
    showAddPlayerSubscriptionModal.value = false;
  }
}

async function handlePaymentMethod({ card, name }) {
  isLoadingPayment.value = true;
  const response = await stripe.createPaymentMethod({
    type: "card",
    card: card,
    billing_details: {
      name: name,
    },
  });

  try {
    await attachPaymentMethod(response.paymentMethod.id);
    await handleAddPlayerConfirm();

    showPaymentModal.value = false;
    showAddPlayerSubscriptionModal.value = true;
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    isLoadingPayment.value = false;
  }
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}

function redirectToRegisterPlayers(numberOfPlayers) {
  router.push({
    name: "Register players",
    query: {
      n: numberOfPlayers,
      from: "profile",
    },
  });
}

function getNotAvailableSeatsContent() {
  return `Currently, you do not have any available seats to add a new player. 
  You can purchase additional seats from your ${
    subscriptionPlatform.value === "Apple"
      ? "Apple ID settings"
      : "Mastermind app settings"
  }.`;
}

function getMaxAvailableSeatsContent() {
  return `At this time, you have reached the maximum number of seats available for your account.`;
}

function getSubscriptionSuspendedContent() {
  if (subscriptionPlatform.value === "Stripe") {
    return `Your subscription is currently suspended. Please reactivate it from your personal information settings.`;
  } else if (
    subscriptionPlatform.value === "Apple" ||
    subscriptionPlatform.value === "Google"
  ) {
    return `Your subscription is currently suspended. Please reactivate it from your ${
      subscriptionPlatform.value === "Apple"
        ? "Apple ID settings"
        : "Mastermind app settings"
    }.`;
  }
}
</script>

<style>
#addPlayer:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-btn {
  background-color: #edfe86;
  border: none;
}
</style>
